/* line 14, scss/80-themes/AffinityDefault/60-components/language-flags.scss */
.c-language-flag {
	list-style: none;
	margin-right: 5px;
	width: 16px;
	height: 13px;
	display: inline-block;
	vertical-align: text-bottom;
	text-indent: 16px;
	background-position: center;
	background-repeat: no-repeat;
	overflow: hidden;
	background-image: url("<%=resources_server%>/_responsive/images/common/default_flags.png");
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .c-language-flag {
	margin-right: initial;
	margin-left: 5px;
}

@media screen and (min-width: 0\0) {
	/* line 7, scss/20-tools/_mixins.scss */
	.is-rtl .c-language-flag {
		margin-right: 0;
	}
}

/* line 29, scss/80-themes/AffinityDefault/60-components/language-flags.scss */
.langflag--eng {
	background-position: -2px -2px;
}

/* line 30, scss/80-themes/AffinityDefault/60-components/language-flags.scss */
.langflag--spu {
	background-position: -2px -18px;
}

/* line 31, scss/80-themes/AffinityDefault/60-components/language-flags.scss */
.langflag--spa {
	background-position: -2px -34px;
}

/* line 32, scss/80-themes/AffinityDefault/60-components/language-flags.scss */
.langflag--deu {
	background-position: -2px -50px;
}

/* line 33, scss/80-themes/AffinityDefault/60-components/language-flags.scss */
.langflag--frc {
	background-position: -2px -66px;
}

/* line 34, scss/80-themes/AffinityDefault/60-components/language-flags.scss */
.langflag--fra {
	background-position: -2px -82px;
}

/* line 35, scss/80-themes/AffinityDefault/60-components/language-flags.scss */
.langflag--ita {
	background-position: -2px -98px;
}

/* line 36, scss/80-themes/AffinityDefault/60-components/language-flags.scss */
.langflag--jpn {
	background-position: -2px -114px;
}

/* line 37, scss/80-themes/AffinityDefault/60-components/language-flags.scss */
.langflag--pob {
	background-position: -2px -130px;
}

/* line 38, scss/80-themes/AffinityDefault/60-components/language-flags.scss */
.langflag--por {
	background-position: -2px -146px;
}

/* line 39, scss/80-themes/AffinityDefault/60-components/language-flags.scss */
.langflag--rus {
	background-position: -2px -162px;
}

/* line 40, scss/80-themes/AffinityDefault/60-components/language-flags.scss */
.langflag--zhs {
	background-position: -2px -178px;
}

/* line 41, scss/80-themes/AffinityDefault/60-components/language-flags.scss */
.langflag--zht {
	background-position: -2px -194px;
}

/* line 42, scss/80-themes/AffinityDefault/60-components/language-flags.scss */
.langflag--vie {
	background-position: -2px -210px;
}

/* line 43, scss/80-themes/AffinityDefault/60-components/language-flags.scss */
.langflag--kor {
	background-position: -2px -226px;
}

/* line 44, scss/80-themes/AffinityDefault/60-components/language-flags.scss */
.langflag--ara {
	background-position: -2px -242px;
}

/* line 45, scss/80-themes/AffinityDefault/60-components/language-flags.scss */
.langflag--tur {
	background-position: -2px -258px;
}

/* line 46, scss/80-themes/AffinityDefault/60-components/language-flags.scss */
.langflag--enb {
	background-position: -2px -274px;
}

/* line 47, scss/80-themes/AffinityDefault/60-components/language-flags.scss */
.langflag--nld {
	background-position: -2px -290px;
}

/* line 48, scss/80-themes/AffinityDefault/60-components/language-flags.scss */
.langflag--swe {
	background-position: -2px -306px;
}

/* line 49, scss/80-themes/AffinityDefault/60-components/language-flags.scss */
.langflag--ell {
	background-position: -2px -322px;
}

/* line 52, scss/80-themes/AffinityDefault/60-components/language-flags.scss */
.global-settings-flag {
	width: 20px;
	height: 20px;
	background-position: center;
	background-repeat: no-repeat;
	background-size: contain;
	margin: 1px 5px;
}

@media only screen and (max-width: 29.999em) {
	/* line 52, scss/80-themes/AffinityDefault/60-components/language-flags.scss */
	.global-settings-flag {
		padding-top: 10px;
	}
	/* line 63, scss/80-themes/AffinityDefault/60-components/language-flags.scss */
	.paw-header--mobile-first .global-settings-flag {
		padding-top: 0;
	}
}

/* line 69, scss/80-themes/AffinityDefault/60-components/language-flags.scss */
.global-settings-flag-list {
	margin-right: 5px;
	width: 15px;
	height: 15px;
	background-position: center;
	background-repeat: no-repeat;
	background-size: contain;
	vertical-align: middle;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .global-settings-flag-list {
	margin-right: initial;
	margin-left: 5px;
}

@media screen and (min-width: 0\0) {
	/* line 7, scss/20-tools/_mixins.scss */
	.is-rtl .global-settings-flag-list {
		margin-right: 0;
	}
}

/* line 81, scss/80-themes/AffinityDefault/60-components/language-flags.scss */
.settflags--eng {
	background-image: url("<%=resources_server%>/_responsive/images/common/settings_flags_svg/us.svg");
}

/* line 82, scss/80-themes/AffinityDefault/60-components/language-flags.scss */
.settflags--spu {
	background-image: url("<%=resources_server%>/_responsive/images/common/settings_flags_svg/mx.svg");
}

/* line 83, scss/80-themes/AffinityDefault/60-components/language-flags.scss */
.settflags--spa {
	background-image: url("<%=resources_server%>/_responsive/images/common/settings_flags_svg/es.svg");
}

/* line 84, scss/80-themes/AffinityDefault/60-components/language-flags.scss */
.settflags--deu {
	background-image: url("<%=resources_server%>/_responsive/images/common/settings_flags_svg/de.svg");
}

/* line 85, scss/80-themes/AffinityDefault/60-components/language-flags.scss */
.settflags--frc {
	background-image: url("<%=resources_server%>/_responsive/images/common/settings_flags_svg/ca.svg");
}

/* line 86, scss/80-themes/AffinityDefault/60-components/language-flags.scss */
.settflags--fra {
	background-image: url("<%=resources_server%>/_responsive/images/common/settings_flags_svg/fr.svg");
}

/* line 87, scss/80-themes/AffinityDefault/60-components/language-flags.scss */
.settflags--ita {
	background-image: url("<%=resources_server%>/_responsive/images/common/settings_flags_svg/it.svg");
}

/* line 88, scss/80-themes/AffinityDefault/60-components/language-flags.scss */
.settflags--jpn {
	background-image: url("<%=resources_server%>/_responsive/images/common/settings_flags_svg/jp.svg");
}

/* line 89, scss/80-themes/AffinityDefault/60-components/language-flags.scss */
.settflags--pob {
	background-image: url("<%=resources_server%>/_responsive/images/common/settings_flags_svg/br.svg");
}

/* line 90, scss/80-themes/AffinityDefault/60-components/language-flags.scss */
.settflags--por {
	background-image: url("<%=resources_server%>/_responsive/images/common/settings_flags_svg/pt.svg");
}

/* line 91, scss/80-themes/AffinityDefault/60-components/language-flags.scss */
.settflags--rus {
	background-image: url("<%=resources_server%>/_responsive/images/common/settings_flags_svg/ru.svg");
}

/* line 92, scss/80-themes/AffinityDefault/60-components/language-flags.scss */
.settflags--zhs {
	background-image: url("<%=resources_server%>/_responsive/images/common/settings_flags_svg/cn.svg");
}

/* line 93, scss/80-themes/AffinityDefault/60-components/language-flags.scss */
.settflags--zht {
	background-image: url("<%=resources_server%>/_responsive/images/common/settings_flags_svg/tw.svg");
}

/* line 94, scss/80-themes/AffinityDefault/60-components/language-flags.scss */
.settflags--vie {
	background-image: url("<%=resources_server%>/_responsive/images/common/settings_flags_svg/vn.svg");
}

/* line 95, scss/80-themes/AffinityDefault/60-components/language-flags.scss */
.settflags--kor {
	background-image: url("<%=resources_server%>/_responsive/images/common/settings_flags_svg/kr.svg");
}

/* line 96, scss/80-themes/AffinityDefault/60-components/language-flags.scss */
.settflags--ara {
	background-image: url("<%=resources_server%>/_responsive/images/common/settings_flags_svg/us.svg");
}

/* line 97, scss/80-themes/AffinityDefault/60-components/language-flags.scss */
.settflags--tur {
	background-image: url("<%=resources_server%>/_responsive/images/common/settings_flags_svg/tr.svg");
}

/* line 98, scss/80-themes/AffinityDefault/60-components/language-flags.scss */
.settflags--enb {
	background-image: url("<%=resources_server%>/_responsive/images/common/settings_flags_svg/us.svg");
}

/* line 99, scss/80-themes/AffinityDefault/60-components/language-flags.scss */
.settflags--nld {
	background-image: url("<%=resources_server%>/_responsive/images/common/settings_flags_svg/nl.svg");
}

/* line 100, scss/80-themes/AffinityDefault/60-components/language-flags.scss */
.settflags--swe {
	background-image: url("<%=resources_server%>/_responsive/images/common/settings_flags_svg/se.svg");
}

/* line 101, scss/80-themes/AffinityDefault/60-components/language-flags.scss */
.settflags--ell {
	background-image: url("<%=resources_server%>/_responsive/images/common/settings_flags_svg/gr.svg");
}

/* line 113, scss/80-themes/AffinityDefault/60-components/language-flags.scss */
.c-language-flag {
	list-style: none;
	margin-right: 5px;
	width: 16px;
	height: 13px;
	display: inline-block;
	vertical-align: text-bottom;
	text-indent: 16px;
	background-position: center;
	background-repeat: no-repeat;
	overflow: hidden;
	background-image: url("<%=resources_server%>/_responsive/images/common/default_flags.png");
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .c-language-flag {
	margin-right: initial;
	margin-left: 5px;
}

@media screen and (min-width: 0\0) {
	/* line 7, scss/20-tools/_mixins.scss */
	.is-rtl .c-language-flag {
		margin-right: 0;
	}
}

/* line 128, scss/80-themes/AffinityDefault/60-components/language-flags.scss */
.langflag--eng {
	background-position: -2px -2px;
}

/* line 129, scss/80-themes/AffinityDefault/60-components/language-flags.scss */
.langflag--spu {
	background-position: -2px -18px;
}

/* line 130, scss/80-themes/AffinityDefault/60-components/language-flags.scss */
.langflag--spa {
	background-position: -2px -34px;
}

/* line 131, scss/80-themes/AffinityDefault/60-components/language-flags.scss */
.langflag--deu {
	background-position: -2px -50px;
}

/* line 132, scss/80-themes/AffinityDefault/60-components/language-flags.scss */
.langflag--frc {
	background-position: -2px -66px;
}

/* line 133, scss/80-themes/AffinityDefault/60-components/language-flags.scss */
.langflag--fra {
	background-position: -2px -82px;
}

/* line 134, scss/80-themes/AffinityDefault/60-components/language-flags.scss */
.langflag--ita {
	background-position: -2px -98px;
}

/* line 135, scss/80-themes/AffinityDefault/60-components/language-flags.scss */
.langflag--jpn {
	background-position: -2px -114px;
}

/* line 136, scss/80-themes/AffinityDefault/60-components/language-flags.scss */
.langflag--pob {
	background-position: -2px -130px;
}

/* line 137, scss/80-themes/AffinityDefault/60-components/language-flags.scss */
.langflag--por {
	background-position: -2px -146px;
}

/* line 138, scss/80-themes/AffinityDefault/60-components/language-flags.scss */
.langflag--rus {
	background-position: -2px -162px;
}

/* line 139, scss/80-themes/AffinityDefault/60-components/language-flags.scss */
.langflag--zhs {
	background-position: -2px -178px;
}

/* line 140, scss/80-themes/AffinityDefault/60-components/language-flags.scss */
.langflag--zht {
	background-position: -2px -194px;
}

/* line 141, scss/80-themes/AffinityDefault/60-components/language-flags.scss */
.langflag--vie {
	background-position: -2px -210px;
}

/* line 142, scss/80-themes/AffinityDefault/60-components/language-flags.scss */
.langflag--kor {
	background-position: -2px -226px;
}

/* line 143, scss/80-themes/AffinityDefault/60-components/language-flags.scss */
.langflag--ara {
	background-position: -2px -242px;
}

/* line 144, scss/80-themes/AffinityDefault/60-components/language-flags.scss */
.langflag--tur {
	background-position: -2px -258px;
}

/* line 145, scss/80-themes/AffinityDefault/60-components/language-flags.scss */
.langflag--enb {
	background-position: -2px -274px;
}

/* line 146, scss/80-themes/AffinityDefault/60-components/language-flags.scss */
.langflag--nld {
	background-position: -2px -290px;
}

/* line 147, scss/80-themes/AffinityDefault/60-components/language-flags.scss */
.langflag--swe {
	background-position: -2px -306px;
}

/* line 148, scss/80-themes/AffinityDefault/60-components/language-flags.scss */
.langflag--ell {
	background-position: -2px -322px;
}

/* line 151, scss/80-themes/AffinityDefault/60-components/language-flags.scss */
.global-settings-flag {
	width: 20px;
	height: 20px;
	background-position: center;
	background-repeat: no-repeat;
	background-size: contain;
	margin: 1px 5px;
}

/* line 164, scss/80-themes/AffinityDefault/60-components/language-flags.scss */
.global-settings-flag-list {
	margin-right: 5px;
	width: 15px;
	height: 15px;
	background-position: center;
	background-repeat: no-repeat;
	background-size: contain;
	vertical-align: middle;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .global-settings-flag-list {
	margin-right: initial;
	margin-left: 5px;
}

@media screen and (min-width: 0\0) {
	/* line 7, scss/20-tools/_mixins.scss */
	.is-rtl .global-settings-flag-list {
		margin-right: 0;
	}
}

/* line 176, scss/80-themes/AffinityDefault/60-components/language-flags.scss */
.settflags--eng {
	background-image: url("<%=resources_server%>/_responsive/images/common/settings_flags_svg/us.svg");
}

/* line 177, scss/80-themes/AffinityDefault/60-components/language-flags.scss */
.settflags--spu {
	background-image: url("<%=resources_server%>/_responsive/images/common/settings_flags_svg/mx.svg");
}

/* line 178, scss/80-themes/AffinityDefault/60-components/language-flags.scss */
.settflags--spa {
	background-image: url("<%=resources_server%>/_responsive/images/common/settings_flags_svg/es.svg");
}

/* line 179, scss/80-themes/AffinityDefault/60-components/language-flags.scss */
.settflags--deu {
	background-image: url("<%=resources_server%>/_responsive/images/common/settings_flags_svg/de.svg");
}

/* line 180, scss/80-themes/AffinityDefault/60-components/language-flags.scss */
.settflags--frc {
	background-image: url("<%=resources_server%>/_responsive/images/common/settings_flags_svg/ca.svg");
}

/* line 181, scss/80-themes/AffinityDefault/60-components/language-flags.scss */
.settflags--fra {
	background-image: url("<%=resources_server%>/_responsive/images/common/settings_flags_svg/fr.svg");
}

/* line 182, scss/80-themes/AffinityDefault/60-components/language-flags.scss */
.settflags--ita {
	background-image: url("<%=resources_server%>/_responsive/images/common/settings_flags_svg/it.svg");
}

/* line 183, scss/80-themes/AffinityDefault/60-components/language-flags.scss */
.settflags--jpn {
	background-image: url("<%=resources_server%>/_responsive/images/common/settings_flags_svg/jp.svg");
}

/* line 184, scss/80-themes/AffinityDefault/60-components/language-flags.scss */
.settflags--pob {
	background-image: url("<%=resources_server%>/_responsive/images/common/settings_flags_svg/br.svg");
}

/* line 185, scss/80-themes/AffinityDefault/60-components/language-flags.scss */
.settflags--por {
	background-image: url("<%=resources_server%>/_responsive/images/common/settings_flags_svg/pt.svg");
}

/* line 186, scss/80-themes/AffinityDefault/60-components/language-flags.scss */
.settflags--rus {
	background-image: url("<%=resources_server%>/_responsive/images/common/settings_flags_svg/ru.svg");
}

/* line 187, scss/80-themes/AffinityDefault/60-components/language-flags.scss */
.settflags--zhs {
	background-image: url("<%=resources_server%>/_responsive/images/common/settings_flags_svg/cn.svg");
}

/* line 188, scss/80-themes/AffinityDefault/60-components/language-flags.scss */
.settflags--zht {
	background-image: url("<%=resources_server%>/_responsive/images/common/settings_flags_svg/tw.svg");
}

/* line 189, scss/80-themes/AffinityDefault/60-components/language-flags.scss */
.settflags--vie {
	background-image: url("<%=resources_server%>/_responsive/images/common/settings_flags_svg/vn.svg");
}

/* line 190, scss/80-themes/AffinityDefault/60-components/language-flags.scss */
.settflags--kor {
	background-image: url("<%=resources_server%>/_responsive/images/common/settings_flags_svg/kr.svg");
}

/* line 191, scss/80-themes/AffinityDefault/60-components/language-flags.scss */
.settflags--ara {
	background-image: url("<%=resources_server%>/_responsive/images/common/settings_flags_svg/us.svg");
}

/* line 192, scss/80-themes/AffinityDefault/60-components/language-flags.scss */
.settflags--tur {
	background-image: url("<%=resources_server%>/_responsive/images/common/settings_flags_svg/tr.svg");
}

/* line 193, scss/80-themes/AffinityDefault/60-components/language-flags.scss */
.settflags--enb {
	background-image: url("<%=resources_server%>/_responsive/images/common/settings_flags_svg/us.svg");
}

/* line 194, scss/80-themes/AffinityDefault/60-components/language-flags.scss */
.settflags--nld {
	background-image: url("<%=resources_server%>/_responsive/images/common/settings_flags_svg/nl.svg");
}

/* line 195, scss/80-themes/AffinityDefault/60-components/language-flags.scss */
.settflags--swe {
	background-image: url("<%=resources_server%>/_responsive/images/common/settings_flags_svg/se.svg");
}

/* line 196, scss/80-themes/AffinityDefault/60-components/language-flags.scss */
.settflags--ell {
	background-image: url("<%=resources_server%>/_responsive/images/common/settings_flags_svg/gr.svg");
}

/*# sourceMappingURL=../../../../true */